import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import nl2br from 'react-nl2br';

import Duration from './Duration';
import Events from './Events'
import Fetch from './Fetch';
import Destroyed from './statistics/Destroyed';
import Fired from './statistics/Fired';
import Hits from './statistics/Hits';
import Landings from './statistics/Landings';
import Pilots from './statistics/Pilots';
import Takeoffs from './statistics/Takeoffs';
import Units from './statistics/Units';
import { Event as EventType } from './types/Event';
import { Mission as MissionType } from './types/Mission';
import { Statistics as StatisticsType } from './types/Statistics';
import { missionUrl, missionStatisticsUrl, eventsUrl } from './urls';

interface MissionProps {
  missionId: string
}

const Mission: React.FC<MissionProps>  = ({ missionId }: MissionProps) => {
  const events = Fetch<EventType[]>(eventsUrl(missionId));
  const mission = Fetch<MissionType>(missionUrl(missionId));
  const statistics = Fetch<StatisticsType>(missionStatisticsUrl(missionId));

  return (
    <div>
      <div>
        {mission.status === 'loading' && <div>Loading mission...</div>}
        {mission.status === 'loaded' && (
          <div>
            <h1>{mission.data.Title}</h1>
            <strong>{mission.data.RecordingTime}</strong>
            <div><Duration duration={mission.data.Duration} /></div>
          </div>
        )}
        {mission.status === 'error' && <div>Error loading mission</div>}
      </div>
      <Tabs defaultActiveKey="description" id="tabs">
        <Tab eventKey="description" title="Description">
          {mission.status === 'loaded' && (
            <p>{mission.data.Description ? nl2br(mission.data.Description): "Mission has no description filled in"}</p>
          )}
        </Tab>

        <Tab eventKey="events" title="Events">
          {events.status === 'loading' && <div>Loading events...</div>}
          {events.status === 'loaded' && (
            <Events events={events.data} />
          )}
          {events.status === 'error' && <div>Error loading events</div>}
        </Tab>

        <Tab eventKey="pilots" title="Pilots">
          {statistics.status === 'loading' && <div>Loading statistics...</div>}
          {statistics.status === 'loaded' && (
            <Pilots destroyed={statistics.data.Destroyed} fired={statistics.data.Fired} hits={statistics.data.Hits} />
          )}
          {statistics.status === 'error' && <div>Error loading statistics</div>}
        </Tab>

        <Tab eventKey="units" title="Units">
          {statistics.status === 'loading' && <div>Loading statistics...</div>}
          {statistics.status === 'loaded' && (
            <Units destroyed={statistics.data.Destroyed} fired={statistics.data.Fired} hits={statistics.data.Hits} />
          )}
          {statistics.status === 'error' && <div>Error loading statistics</div>}
        </Tab>
        
        <Tab eventKey="fired" title="Fired Weapons">
          {statistics.status === 'loading' && <div>Loading statistics...</div>}
          {statistics.status === 'loaded' && (
            <Fired fired={statistics.data.Fired} hits={statistics.data.Hits} />
          )}
          {statistics.status === 'error' && <div>Error loading statistics</div>}
        </Tab>

        <Tab eventKey="hits" title="Hit Targets">
          {statistics.status === 'loading' && <div>Loading statistics...</div>}
          {statistics.status === 'loaded' && (
            <Hits hits={statistics.data.Hits} />
          )}
          {statistics.status === 'error' && <div>Error loading statistics</div>}
        </Tab>

        <Tab eventKey="destroyed" title="Destroyed">
          {statistics.status === 'loading' && <div>Loading statistics...</div>}
          {statistics.status === 'loaded' && (
            <Destroyed destroyed={statistics.data.Destroyed} />
          )}
          {statistics.status === 'error' && <div>Error loading statistics</div>}
        </Tab>

        <Tab eventKey="takeoffs" title="Takeoffs">
          {statistics.status === 'loading' && <div>Loading statistics...</div>}
          {statistics.status === 'loaded' && (
            <Takeoffs takeoffs={statistics.data.Takeoffs} />
          )}
          {statistics.status === 'error' && <div>Error loading statistics</div>}
        </Tab>

        <Tab eventKey="landings" title="Landings">
          {statistics.status === 'loading' && <div>Loading statistics...</div>}
          {statistics.status === 'loaded' && (
            <Landings landings={statistics.data.Landings} />
          )}
          {statistics.status === 'error' && <div>Error loading statistics</div>}
        </Tab>
      </Tabs>
    </div>
  );
}

export default Mission;
