import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Fetch from './Fetch';
import { Pilot } from './types/Pilot';
import { pilotsUrl } from './urls'

const Pilots: React.FC = () => {
  const pilots = Fetch<Pilot[]>(pilotsUrl());

  return (
    <div>
      {pilots.status === 'loading' && <div>Loading pilots...</div>}
      {pilots.status === 'loaded' && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Missions</th>
            </tr>
          </thead>
          <tbody>
            {pilots.data.map((pilot: Pilot, idx: number) => (
              <tr key={idx}>
                <td>
                  <Link to={`/pilots/${pilot.Name}`}>{pilot.Name}</Link>
                 </td>
                 <td>{pilot.Missions}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {pilots.status === 'error' && <div>Error loading pilots</div>}
    </div>
  );
}

export default Pilots;
