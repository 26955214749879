import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';
import React from 'react';
import { Form, Table } from 'react-bootstrap';

import formatName from '../formatName';
import { Fired, Hit, Weapon } from '../types/Statistics';

interface FiredStatsProps {
  fired: Fired[]
  hits: Hit[]
}

export interface WeaponStats {
  Weapon: Weapon;
  Amount: number;
}

function sumFired(fired: Fired[]): WeaponStats {
  return {
    Weapon: fired[0].Weapon,
    Amount: sumBy(fired, 'Amount')
  }
}

function sumHits(hits: Hit[]): WeaponStats {
  return {
    Weapon: hits[0].Weapon,
    Amount: sumBy(hits, 'Amount')
  }
}

const FiredStats: React.FC<FiredStatsProps> = (props: FiredStatsProps) => {
  const weaponTypes = keys(groupBy(props.fired, (fired: Fired) => `${fired.Weapon.Type}`)).sort();
  const [selectedWeaponTypes, setSelectedWeaponTypes] = React.useState(weaponTypes);

  const updateSelectedWeaponTypes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.id
    const active = selectedWeaponTypes.indexOf(type) >= 0

    if (active) {
      setSelectedWeaponTypes(selectedWeaponTypes.filter((activeType) => activeType !== type))
    } else {
      setSelectedWeaponTypes([...selectedWeaponTypes, type])
    }
  }

  const filteredFired = props.fired.filter((fired: Fired) => selectedWeaponTypes.indexOf(fired.Weapon.Type) >= 0)
  const groupedFired = groupBy(filteredFired, (fired: Fired) => `${fired.Weapon.Name}`);
  const summedFired = mapValues(groupedFired, sumFired);
  const fired = reverse(sortBy(values(summedFired), 'Amount'));

  const groupedHits = groupBy(props.hits, (hit: Hit) => `${hit.Weapon.Name}`);
  const summedHits = mapValues(groupedHits, sumHits);

  return (
    <div>
      <Form>
        <strong>Filter Weapon Types</strong>
        <br/>
        {weaponTypes.map(type => (
          <Form.Check
            key={type}
            checked={selectedWeaponTypes.indexOf(type) >= 0}
            onChange={updateSelectedWeaponTypes}
            inline
            type={'checkbox'}
            id={type}
            label={type}
          />
        ))}
      </Form>

      <Table bordered responsive size="sm" striped>
        <thead>
          <tr>
            <th colSpan={2}>Weapon</th>
            <th rowSpan={2}>Amount</th>
            <th rowSpan={2}>Hits</th>
            <th rowSpan={2}>Percent Hit</th>
          </tr>
          <tr>
            <th>Type</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {fired.map((weapon: WeaponStats, idx: number) => {
            const hitMatch = summedHits[`${weapon.Weapon.Name}`];
            const hits = hitMatch ? hitMatch.Amount : 0;
            const hitsRatio = hits / weapon.Amount;

            return (
              <tr key={idx}>
                <td>{weapon.Weapon.Type}</td>
                <td>{formatName(weapon.Weapon.Name)}</td>
                <td>{weapon.Amount}</td>
                <td>{hits}</td>
                <td>{(hitsRatio * 100).toFixed(0)} %</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default FiredStats;
