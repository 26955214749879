import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Fetch from './Fetch';
import { Object } from './types/Object';
import { Unit } from './types/Unit';
import { unitsUrl } from './urls';

export interface GroupedUnit {
  Object: Object;
}

function sumUnits(unit: Unit[]): GroupedUnit {
  return {
    Object: unit[0].Object,
  }
}

interface UnitsTableProps {
  units: Unit[]
}

const UnitsTable: React.FC<UnitsTableProps> = (props: UnitsTableProps) => {
  const groupedUnits = groupBy(props.units, (unit: Unit) => unit.Object.Name)
  const summedUnits = mapValues(groupedUnits, sumUnits);
  const units = sortBy(values(summedUnits), 'Object.Name');

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan={2}>Unit</th>
        </tr>
        <tr>
          <th>Name</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {units.map((unit: GroupedUnit, idx: number) => (
          <tr key={idx}>
            <td>
              <Link to={`/units/${encodeURIComponent(unit.Object.Name)}`}>{unit.Object.Name}</Link>
            </td>
            <td>{unit.Object.Type}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const Units: React.FC = () => {
  const units = Fetch<Unit[]>(unitsUrl());

  return (
    <div>
      {units.status === 'loading' && <div>Loading units...</div>}
      {units.status === 'loaded' && (
        <UnitsTable units={units.data} />
      )}
      {units.status === 'error' && <div>Error loading units</div>}
    </div>
  );
}

export default Units;
