import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import React from 'react';
import { Table } from 'react-bootstrap';

import { Destroyed, Fired, Hit } from '../types/Statistics';

interface UnitsProps {
  destroyed: Destroyed[]
  fired: Fired[]
  hits: Hit[]
}

const typesAircraft = ['Aircraft', 'Helicopter']
const typesShip = ['Carrier', 'Ship']

const Units: React.FC<UnitsProps> = (props: UnitsProps) => {
  const groupedFired = groupBy(props.fired, (fired: Fired) => fired.Object.Name)
  const groupedHits = groupBy(props.hits, (hit: Hit) => hit.Object.Name)
  const groupedDestroyed = groupBy(props.destroyed, (destroyed: Destroyed) => destroyed.Object.Name)

  const units = uniq(keys(groupedFired).concat(keys(groupedHits)).concat(keys(groupedDestroyed))).sort().filter((pilot: string) => pilot)

  return (
    <Table bordered responsive size="sm" striped>
      <thead>
        <tr>
          <th rowSpan={2}>Unit</th>
          <th colSpan={2}>Missiles</th>
          <th colSpan={2}>Bombs</th>
          <th colSpan={2}>Rockets</th>
          <th colSpan={2}>Shells</th>
          <th colSpan={3}>Destroyed</th>
        </tr>
        <tr>
          <th>Fired</th>
          <th>Hits</th>
          <th>Fired</th>
          <th>Hits</th>
          <th>Fired</th>
          <th>Hits</th>
          <th>Fired</th>
          <th>Hits</th>
          <th>Aircraft</th>
          <th>Ground</th>
          <th>Ships</th>
        </tr>
      </thead>
      <tbody>
        {units.map((unit: string) => {
          const unitFired = groupedFired[unit] || []
          const firedBombs = unitFired.filter((fired: Fired) => fired.Weapon.Type === 'Bomb')
          const firedMissiles = unitFired.filter((fired: Fired) => fired.Weapon.Type === 'Missile')
          const firedRockets = unitFired.filter((fired: Fired) => fired.Weapon.Type === 'Rocket')
          const firedShells = unitFired.filter((fired: Fired) => fired.Weapon.Type === 'Shell')

          const unitHits = groupedHits[unit] || []
          const hitsBombs = unitHits.filter((hit: Fired) => hit.Weapon.Type === 'Bomb')
          const hitsMissiles = unitHits.filter((hit: Fired) => hit.Weapon.Type === 'Missile')
          const hitsRockets = unitHits.filter((hit: Fired) => hit.Weapon.Type === 'Rocket')
          const hitsShells = unitHits.filter((hit: Hit) => hit.Weapon.Type === 'Shell')

          const unitDestroyed = groupedDestroyed[unit] || []
          const destroyedAircraft = unitDestroyed.filter((destroyed: Destroyed) => typesAircraft.includes(destroyed.Target.Type))
          const destroyedGround = unitDestroyed.filter((destroyed: Destroyed) => !(typesAircraft.concat(typesShip).includes(destroyed.Target.Type)))
          const destroyedShip = unitDestroyed.filter((destroyed: Destroyed) => typesShip.includes(destroyed.Target.Type))

          const sumAmount = (array: Array<any>) => sumBy(array, 'Amount') || '-'

          return (
            <tr key={unit}>
              <td>{unit}</td>
              <td>{sumAmount(firedMissiles)}</td>
              <td>{sumAmount(hitsMissiles)}</td>
              <td>{sumAmount(firedBombs)}</td>
              <td>{sumAmount(hitsBombs)}</td>
              <td>{sumAmount(firedRockets)}</td>
              <td>{sumAmount(hitsRockets)}</td>
              <td>{sumAmount(firedShells)}</td>
              <td>{sumAmount(hitsShells)}</td>
              <td>{sumAmount(destroyedAircraft)}</td>
              <td>{sumAmount(destroyedGround)}</td>
              <td>{sumAmount(destroyedShip)}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default Units;
