import React from 'react';

interface DurationProps {
  duration: number
}

const Duration: React.FC<DurationProps>  = ({ duration }: DurationProps) => {
  const hours = (duration / 60 / 60).toFixed(0).toString().padStart(2, '0')
  const minutes = (duration / 60 % 60).toFixed(0).toString().padStart(2, '0')
  const seconds = (duration % 60).toFixed(0).toString().padStart(2, '0')

  return (
    <span>{`${hours}:${minutes}:${seconds}`}</span>
  )
}

export default Duration;
