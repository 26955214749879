import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Duration from './Duration';
import Fetch from './Fetch';
import { Mission } from './types/Mission';
import { missionsUrl } from './urls'

const Missions: React.FC = () => {
  const missions = Fetch<Mission[]>(missionsUrl());

  return (
    <div>
      {missions.status === 'loading' && <div>Loading missions...</div>}
      {missions.status === 'loaded' && (
        <Table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Duration</th>
              <th>Recording Date</th>
            </tr>
          </thead>
          <tbody>
            {missions.data.map((mission: Mission) => (
              <tr key={mission.ID}>
                <td>
                  <Link to={`/missions/${mission.ID}`}>{mission.Title}</Link>
                </td>
                <td><Duration duration={mission.Duration} /></td>
                <td>{mission.RecordingTime}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {missions.status === 'error' && <div>Error loading missions</div>}
    </div>
  );
}

export default Missions;
