import React from 'react';
import { Table } from 'react-bootstrap';

import Duration from './Duration';
import { Event, EventObject } from './types/Event';

interface EventProps {
  event: Event
}

interface EventsProps {
  events: Event[]
}

interface EventObjectProps {
  object: EventObject
}

const Object: React.FC<EventObjectProps>  = ({ object }: EventObjectProps) => {
  return (
    <span>
      {object.Name} <strong>{object.Pilot} {object.Group}</strong>
    </span>
  )
}

const EventHasBeenDestroyed: React.FC<EventProps>  = ({ event }: EventProps) => {
  return (
    <div>
      <Object object={event.PrimaryObject!} /> was destroyed
      {event.SecondaryObject != null ? (<span> by <Object object={event.SecondaryObject} /></span>) : ''}
    </div>
  )
}

const EventHasBeenHitBy: React.FC<EventProps>  = ({ event }: EventProps) => {
  return (
    <div>
      <Object object={event.PrimaryObject!} /> was hit
      {event.SecondaryObject != null ? (<span> by <Object object={event.SecondaryObject} /></span>) : ''}
      {event.ParentObject != null ? (<span> from <Object object={event.ParentObject} /></span>) : ''}
    </div>
  )
}

const EventHasEnteredTheArea: React.FC<EventProps>  = ({ event }: EventProps) => {
  return (
    <div>
      <Object object={event.PrimaryObject!} /> entered the area
      {event.Airport ? ` at ${event.Airport.Name}` : ''}
      {event.SecondaryObject != null ? ` at ${event.SecondaryObject.Name}` : ''}
    </div>
  )
}

const EventHasLanded: React.FC<EventProps>  = ({ event }: EventProps) => {
  return (
    <div>
      <Object object={event.PrimaryObject!} /> landed
      {event.Airport ? ` at ${event.Airport.Name}` : ''}
      {event.SecondaryObject != null ? ` at ${event.SecondaryObject.Name}` : ''}
    </div>
  )
}

const EventHasLeftTheArea: React.FC<EventProps>  = ({ event }: EventProps) => {
  return (
    <div>
      <Object object={event.PrimaryObject!} /> left the area
      {event.Airport ? ` from ${event.Airport.Name}` : ''}
      {event.SecondaryObject != null ? ` from ${event.SecondaryObject.Name}` : ''}
    </div>
  )
}

const EventHasTakenOff: React.FC<EventProps>  = ({ event }: EventProps) => {
  return (
    <div>
      <Object object={event.PrimaryObject!} /> took off
      {event.Airport ? ` from ${event.Airport.Name}` : ''}
      {event.SecondaryObject != null ? ` from ${event.SecondaryObject.Name}` : ''}
    </div>
  )
}

const EventHasFired: React.FC<EventProps>  = ({ event }: EventProps) => {
  return (
    <div>
      <Object object={event.PrimaryObject!} />
      &nbsp;fired&nbsp;
      {event.SecondaryObject?.Name}
    </div>
  )
}

const EventDescription: React.FC<EventProps>  = ({ event }: EventProps) => {
  switch (event.Action) {
    case 'HasBeenDestroyed':
      return <EventHasBeenDestroyed event={event} />
    case 'HasBeenHitBy':
      return <EventHasBeenHitBy event={event} />
    case 'HasEnteredTheArea':
      return <EventHasEnteredTheArea event={event} />
    case 'HasFired':
      return <EventHasFired event={event} />
    case 'HasLanded':
      return <EventHasLanded event={event} />
    case 'HasLeftTheArea':
      return <EventHasLeftTheArea event={event} />
    case 'HasTakenOff':
      return <EventHasTakenOff event={event} />
    default: {
      console.log(`Unknown action ${event.Action}`)
      return null
    }
  }
}

const Events: React.FC<EventsProps>  = ({ events }: EventsProps) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Time</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event: Event, idx: number) => (
          <tr key={idx}>
            <td><Duration duration={event.Time} /></td>
            <td><EventDescription event={event}  key={idx} /></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default Events;
