import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';
import React from 'react';
import { Table } from 'react-bootstrap';

import formatName from '../formatName';
import { Object } from '../types/Object';
import { Destroyed } from '../types/Statistics';

export interface ObjectsDestroyed {
  Object: Object;
  Amount: number;
}

function sumDestroyed(destroyed: Destroyed[]): ObjectsDestroyed {
  return {
    Object: destroyed[0].Target,
    Amount: sumBy(destroyed, 'Amount')
  }
}

interface DestroyedStatsProps {
  destroyed: Destroyed[]
}

const DestroyedStats: React.FC<DestroyedStatsProps> = (props: DestroyedStatsProps) => {
  const groupedDestroyed = groupBy(props.destroyed, (destroyed: Destroyed) => `${destroyed.Target.Type}-${destroyed.Target.Name}`);
  const summedDestroyed = mapValues(groupedDestroyed, sumDestroyed);
  const destroyed = reverse(sortBy(values(summedDestroyed), 'Amount'));

  return (
    <Table bordered responsive size="sm" striped>
      <thead>
        <tr>
          <th colSpan={2}>Unit</th>
          <th rowSpan={2}>Amount</th>
        </tr>
        <tr>
          <th>Type</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {destroyed.map((destroyed: ObjectsDestroyed, idx: number) => (
          <tr key={idx}>
            <td>{destroyed.Object.Type}</td>
            <td>{formatName(destroyed.Object.Name)}</td>
            <td>{destroyed.Amount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default DestroyedStats;
