import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import sumBy from 'lodash/sumBy';

import Fetch from './Fetch';
import Destroyed from './statistics/Destroyed';
import Fired from './statistics/Fired';
import Hits from './statistics/Hits';
import Landings from './statistics/Landings';
import Missions from './statistics/Missions';
import Takeoffs from './statistics/Takeoffs';
import Units from './statistics/Units';
import { PilotStatistics } from './types/Pilot';
import { pilotUrl } from './urls';

interface PilotStatsProps {
  pilot: PilotStatistics
}

const Overall: React.FC<PilotStatsProps> = (props: PilotStatsProps) => {
  return (
    <div>
      <dl className="row">
        <dt className="col-sm-3">Missions</dt>
        <dd className="col-sm-9">{props.pilot.Missions.length}</dd>
      </dl>

      <dl className="row">
        <dt className="col-sm-3">Fired Weapons</dt>
        <dd className="col-sm-9">{sumBy(props.pilot.Fired, 'Amount')}</dd>
      </dl>

      <dl className="row">
        <dt className="col-sm-3">Hit Targets</dt>
        <dd className="col-sm-9">{sumBy(props.pilot.Hits, 'Amount')}</dd>
      </dl>

      <dl className="row">
        <dt className="col-sm-3">Destroyed Targets</dt>
        <dd className="col-sm-9">{sumBy(props.pilot.Destroyed, 'Amount')}</dd>
      </dl>

      <dl className="row">
        <dt className="col-sm-3">Takeoffs</dt>
        <dd className="col-sm-9">{sumBy(props.pilot.Takeoffs, 'Amount')}</dd>
      </dl>

      <dl className="row">
        <dt className="col-sm-3">Landings</dt>
        <dd className="col-sm-9">{sumBy(props.pilot.Landings, 'Amount')}</dd>
      </dl>
    </div>
  )
}

interface PilotProps {
  pilotName: string
}

const Pilot: React.FC<PilotProps> = (props: PilotProps) => {
  const pilot = Fetch<PilotStatistics>(pilotUrl(props.pilotName));

  return (
    <div>
      <h1>{props.pilotName}</h1>
      {pilot.status === 'loading' && <div>Loading pilot...</div>}
      {pilot.status === 'loaded' && (
        <Tabs defaultActiveKey="overall" id="pilot-statistics">
          <Tab eventKey="overall" title="Overall">
            <Overall pilot={pilot.data} />
          </Tab>

          <Tab eventKey="missions" title="Missions">
            <Missions missions={pilot.data.Missions} />
          </Tab>

          <Tab eventKey="units" title="Units">
            <Units destroyed={pilot.data.Destroyed} fired={pilot.data.Fired} hits={pilot.data.Hits} />
          </Tab>

          <Tab eventKey="fired" title="Fired Weapons">
            <Fired fired={pilot.data.Fired} hits={pilot.data.Hits} />
          </Tab>

          <Tab eventKey="hits" title="Hit Targets">
            <Hits hits={pilot.data.Hits} />
          </Tab>

          <Tab eventKey="destroyed" title="Destroyed Targets">
            <Destroyed destroyed={pilot.data.Destroyed} />
          </Tab>

          <Tab eventKey="takeoffs" title="Takeoffs">
            <Takeoffs takeoffs={pilot.data.Takeoffs} />
          </Tab>

          <Tab eventKey="landings" title="Landings">
            <Landings landings={pilot.data.Landings} />
          </Tab>
        </Tabs>
      )}
      {pilot.status === 'error' && <div>Error loading pilot</div>}
    </div>
  );
}

export default Pilot;
