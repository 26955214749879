import React from 'react';
import { Table } from 'react-bootstrap';

import { Landing } from '../types/Statistics';

interface LandingsStatsProps {
  landings: Landing[]
}

const LandingsStats: React.FC<LandingsStatsProps> = (props: LandingsStatsProps) => {
  return (
    <Table bordered responsive size="sm" striped>
      <thead>
        <tr>
          <th colSpan={3}>Unit</th>
          <th rowSpan={2}>Airport</th>
          <th rowSpan={2}>Amount</th>
        </tr>
        <tr>
          <th>Type</th>
          <th>Name</th>
          <th>Pilot</th>
        </tr>
      </thead>
      <tbody>
        {props.landings.map((landing: Landing, idx: number) => (
          <tr key={idx}>
            <td>{landing.Object.Type}</td>
            <td>{landing.Object.Name}</td>
            <td>{landing.Object.Pilot || '-'}</td>
            <td>{landing.Airport || '-'}</td>
            <td>{landing.Amount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default LandingsStats;
