import React from 'react';
import { Table } from 'react-bootstrap';

import formatName from '../formatName';
import { Hit } from '../types/Statistics';

interface HitsStatsProps {
  hits: Hit[]
}

const HitsStats: React.FC<HitsStatsProps> = (props: HitsStatsProps) => {
  return (
    <Table bordered responsive size="sm" striped>
      <thead>
        <tr>
          <th colSpan={3}>Unit</th>
          <th colSpan={3}>Target</th>
          <th colSpan={2}>Weapon</th>
          <th rowSpan={2}>Amount</th>
        </tr>
        <tr>
          <th>Type</th>
          <th>Name</th>
          <th>Pilot</th>
          <th>Type</th>
          <th>Name</th>
          <th>Pilot</th>
          <th>Type</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {props.hits.map((hit: Hit, idx: number) => (
          <tr key={idx}>
            <td>{hit.Object.Type}</td>
            <td>{hit.Object.Name}</td>
            <td>{hit.Object.Pilot || '-'}</td>
            <td>{hit.Target.Type}</td>
            <td>{formatName(hit.Target.Name)}</td>
            <td>{hit.Target.Pilot || '-'}</td>
            <td>{hit.Weapon.Type}</td>
            <td>{formatName(hit.Weapon.Name)}</td>
            <td>{hit.Amount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default HitsStats;
