import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import Fetch from './Fetch';
import Destroyed from './statistics/Destroyed';
import Fired from './statistics/Fired';
import Hits from './statistics/Hits';
import Landings from './statistics/Landings';
import Missions from './statistics/Missions';
import Pilots from './statistics/Pilots';
import Takeoffs from './statistics/Takeoffs';
import Units from './statistics/Units';
import { UnitStatistics } from './types/Unit';
import { unitUrl } from './urls';

interface UnitProps {
  unitName: string
}

const Unit: React.FC<UnitProps> = (props: UnitProps) => {
  const unit = Fetch<UnitStatistics>(unitUrl(props.unitName));

  return (
    <div>
      <h1>{decodeURIComponent(props.unitName)}</h1>
      {unit.status === 'loading' && <div>Loading unit...</div>}
      {unit.status === 'loaded' && (
        <Tabs defaultActiveKey="pilots" id="unit-statistics">
          <Tab eventKey="pilots" title="Pilots">
            <Pilots destroyed={unit.data.Destroyed} fired={unit.data.Fired} hits={unit.data.Hits} />
          </Tab>

          <Tab eventKey="missions" title="Missions">
            <Missions missions={unit.data.Missions} />
          </Tab>

          <Tab eventKey="units" title="Units">
            <Units destroyed={unit.data.Destroyed} fired={unit.data.Fired} hits={unit.data.Hits} />
          </Tab>

          <Tab eventKey="fired" title="Fired Weapons">
            <Fired fired={unit.data.Fired} hits={unit.data.Hits} />
          </Tab>

          <Tab eventKey="hits" title="Hit Targets">
            <Hits hits={unit.data.Hits} />
          </Tab>

          <Tab eventKey="destroyed" title="Destroyed Targets">
            <Destroyed destroyed={unit.data.Destroyed} />
          </Tab>

          <Tab eventKey="takeoffs" title="Takeoffs">
            <Takeoffs takeoffs={unit.data.Takeoffs} />
          </Tab>

          <Tab eventKey="landings" title="Landings">
            <Landings landings={unit.data.Landings} />
          </Tab>
        </Tabs>
      )}
      {unit.status === 'error' && <div>Error loading unit</div>}
    </div>
  );
}

export default Unit;
