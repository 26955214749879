const baseUrl = 'https://api.tacview.anrop.se'

export function eventsUrl (id: string): string { return `${baseUrl}/missions/${id}/events` }
export function missionsUrl (): string { return `${baseUrl}/missions` }
export function missionUrl (id: string): string { return `${baseUrl}/missions/${id}` }
export function missionStatisticsUrl (id: string): string { return `${baseUrl}/missions/${id}/stats` }
export function pilotsUrl (): string { return `${baseUrl}/pilots` }
export function pilotUrl (name: string): string { return `${baseUrl}/pilots/${name}` }
export function statisticsUrl (): string { return `${baseUrl}/stats` }
export function unitsUrl (): string { return `${baseUrl}/units` }
export function unitUrl (name: string): string { return `${baseUrl}/units/${name}` }
