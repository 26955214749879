import React from 'react';
import { Table } from 'react-bootstrap';

import { Takeoff } from '../types/Statistics';

interface TakeoffsStatsProps {
  takeoffs: Takeoff[]
}

const TakeoffsStats: React.FC<TakeoffsStatsProps> = (props: TakeoffsStatsProps) => {
  return (
    <Table bordered responsive size="sm" striped>
      <thead>
        <tr>
          <th colSpan={3}>Unit</th>
          <th rowSpan={2}>Airport</th>
          <th rowSpan={2}>Amount</th>
        </tr>
        <tr>
          <th>Type</th>
          <th>Name</th>
          <th>Pilot</th>
        </tr>
      </thead>
      <tbody>
        {props.takeoffs.map((takeoff: Takeoff, idx: number) => (
          <tr key={idx}>
            <td>{takeoff.Object.Type}</td>
            <td>{takeoff.Object.Name}</td>
            <td>{takeoff.Object.Pilot || '-'}</td>
            <td>{takeoff.Airport || '-'}</td>
            <td>{takeoff.Amount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default TakeoffsStats;
