import { createBrowserHistory } from 'history';
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom';

import Mission from './Mission';
import Missions from './Missions';
import Pilot from './Pilot';
import Pilots from './Pilots';
import Statistics from './Statistics';
import Unit from './Unit';
import Units from './Units';
import './App.css';

interface MissionMatchParams {
  id: string;
}
interface MissionMatchProps extends RouteComponentProps<MissionMatchParams> {}

interface PilotMatchParams {
  name: string;
}
interface PilotMatchProps extends RouteComponentProps<PilotMatchParams> {}

interface UnitMatchParams {
  name: string;
}
interface UnitMatchProps extends RouteComponentProps<UnitMatchParams> {}

const App: React.FC = () => {
  return (
    <Router history={createBrowserHistory()}>
      <Navbar bg="light" expand="lg" fixed="top">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>Tacview</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to="/missions">
                <Nav.Link>Missions</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/pilots">
                <Nav.Link>Pilots</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/units">
                <Nav.Link>Units</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/statistics">
                <Nav.Link>Statistics</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <Switch>
          <Route path="/missions/:id" render={(routeProps: MissionMatchProps) => (
            <Mission missionId={routeProps.match.params.id} />
          )} />
          <Route path="/missions">
            <Missions />
          </Route>
          <Route path="/pilots/:name" render={(routeProps: PilotMatchProps) => (
            <Pilot pilotName={routeProps.match.params.name} />
          )} />
          <Route path="/pilots">
            <Pilots />
          </Route>
          <Route path="/statistics">
            <Statistics />
          </Route>
          <Route path="/units/:name" render={(routeProps: UnitMatchProps) => (
            <Unit unitName={routeProps.match.params.name} />
          )} />
          <Route path="/units">
            <Units />
          </Route>
          <Route path="/">
            <Redirect to="/missions" />
          </Route>
        </Switch>
      </Container>
     </Router>
  );
}

export default App;
