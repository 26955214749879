import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Mission } from '../types/Mission';

interface MissionsStatsProps {
  missions: Mission[]
}

const MissionsStats: React.FC<MissionsStatsProps> = (props: MissionsStatsProps) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {props.missions.map((mission: Mission) => (
          <tr key={mission.ID}>
            <td>
              <Link to={`/missions/${mission.ID}`}>{mission.Title}</Link>
             </td>
            <td>{mission.RecordingTime}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default MissionsStats;
