import { useEffect, useState } from 'react';

import { Service } from './types/Service';

function Fetch<T> (url: string) {
  const [result, setResult] = useState<Service<T>>({
    status: 'loading'
  });

  useEffect(() => {
    if (url) {
      setResult({ status: 'loading' });
      fetch(url)
        .then(response => response.json())
        .then(response => setResult({ status: 'loaded', data: response }))
        .catch(error => setResult({ status: 'error', error }));
    }
  }, [url]);

  return result;
};

export default Fetch;
