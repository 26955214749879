import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import React from 'react';
import { Table } from 'react-bootstrap';

import { Destroyed, Fired, Hit} from '../types/Statistics';

interface PilotsProps {
  destroyed: Destroyed[]
  fired: Fired[]
  hits: Hit[]
}

const typesAircraft = ['Aircraft', 'Helicopter']
const typesShip = ['Carrier', 'Ship']

const Pilots: React.FC<PilotsProps> = (props: PilotsProps) => {
  const groupedFired = groupBy(props.fired, (fired: Fired) => fired.Object.Pilot)
  const groupedHits = groupBy(props.hits, (hit: Hit) => hit.Object.Pilot)
  const groupedDestroyed = groupBy(props.destroyed, (destroyed: Destroyed) => destroyed.Object.Pilot)

  const pilots = uniq(keys(groupedFired).concat(keys(groupedHits)).concat(keys(groupedDestroyed))).sort().filter((pilot: string) => pilot)

  return (
    <Table bordered responsive size="sm" striped>
      <thead>
        <tr>
          <th rowSpan={2}>Pilot</th>
          <th colSpan={2}>Missiles</th>
          <th colSpan={2}>Bombs</th>
          <th colSpan={2}>Rockets</th>
          <th colSpan={2}>Shells</th>
          <th colSpan={3}>Destroyed</th>
        </tr>
        <tr>
          <th>Fired</th>
          <th>Hits</th>
          <th>Fired</th>
          <th>Hits</th>
          <th>Fired</th>
          <th>Hits</th>
          <th>Fired</th>
          <th>Hits</th>
          <th>Aircraft</th>
          <th>Ground</th>
          <th>Ships</th>
        </tr>
      </thead>
      <tbody>
        {pilots.map((pilot: string) => {
          const pilotFired = groupedFired[pilot] || []
          const firedBombs = pilotFired.filter((fired: Fired) => fired.Weapon.Type === 'Bomb')
          const firedMissiles = pilotFired.filter((fired: Fired) => fired.Weapon.Type === 'Missile')
          const firedRockets = pilotFired.filter((fired: Fired) => fired.Weapon.Type === 'Rocket')
          const firedShells = pilotFired.filter((fired: Fired) => fired.Weapon.Type === 'Shell')

          const pilotHits = groupedHits[pilot] || []
          const hitsBombs = pilotHits.filter((hit: Fired) => hit.Weapon.Type === 'Bomb')
          const hitsMissiles = pilotHits.filter((hit: Fired) => hit.Weapon.Type === 'Missile')
          const hitsRockets = pilotHits.filter((hit: Fired) => hit.Weapon.Type === 'Rocket')
          const hitsShells = pilotHits.filter((hit: Hit) => hit.Weapon.Type === 'Shell')

          const pilotDestroyed = groupedDestroyed[pilot] || []
          const destroyedAircraft = pilotDestroyed.filter((destroyed: Destroyed) => typesAircraft.includes(destroyed.Target.Type))
          const destroyedGround = pilotDestroyed.filter((destroyed: Destroyed) => !(typesAircraft.concat(typesShip).includes(destroyed.Target.Type)))
          const destroyedShip = pilotDestroyed.filter((destroyed: Destroyed) => typesShip.includes(destroyed.Target.Type))

          const sumAmount = (array: Array<any>) => sumBy(array, 'Amount') || '-'

          return (
            <tr key={pilot}>
              <td>{pilot}</td>
              <td>{sumAmount(firedMissiles)}</td>
              <td>{sumAmount(hitsMissiles)}</td>
              <td>{sumAmount(firedBombs)}</td>
              <td>{sumAmount(hitsBombs)}</td>
              <td>{sumAmount(firedRockets)}</td>
              <td>{sumAmount(hitsRockets)}</td>
              <td>{sumAmount(firedShells)}</td>
              <td>{sumAmount(hitsShells)}</td>
              <td>{sumAmount(destroyedAircraft)}</td>
              <td>{sumAmount(destroyedGround)}</td>
              <td>{sumAmount(destroyedShip)}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default Pilots;
