import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import Fetch from './Fetch';
import Destroyed from './statistics/Destroyed';
import Fired from './statistics/Fired';
import Hits from './statistics/Hits';
import Landings from './statistics/Landings';
import Pilots from './statistics/Pilots';
import Takeoffs from './statistics/Takeoffs';
import Units from './statistics/Units';
import { Statistics as StatisticsType } from './types/Statistics';
import { statisticsUrl } from './urls';

const Statistics: React.FC = () => {
  const statistics = Fetch<StatisticsType>(statisticsUrl());

  return (
    <div>
      <h1>Statistics</h1>
      {statistics.status === 'loading' && <div>Loading statistics...</div>}
      {statistics.status === 'loaded' && (
        <Tabs defaultActiveKey="pilots" id="statistics">
          <Tab eventKey="pilots" title="Pilots">
            <Pilots destroyed={statistics.data.Destroyed} fired={statistics.data.Fired} hits={statistics.data.Hits} />
          </Tab>

          <Tab eventKey="units" title="Units">
            <Units destroyed={statistics.data.Destroyed} fired={statistics.data.Fired} hits={statistics.data.Hits} />
          </Tab>

          <Tab eventKey="fired" title="Fired Weapons">
            <Fired fired={statistics.data.Fired} hits={statistics.data.Hits} />
          </Tab>

          <Tab eventKey="hits" title="Hit Targets">
            <Hits hits={statistics.data.Hits} />
          </Tab>

          <Tab eventKey="destroyed" title="Destroyed">
            <Destroyed destroyed={statistics.data.Destroyed} />
          </Tab>

          <Tab eventKey="takeoffs" title="Takeoffs">
            <Takeoffs takeoffs={statistics.data.Takeoffs} />
          </Tab>

          <Tab eventKey="landings" title="Landings">
            <Landings landings={statistics.data.Landings} />
          </Tab>
        </Tabs>
      )}
      {statistics.status === 'error' && <div>Error loading statistics</div>}
    </div>
  );
}

export default Statistics;
